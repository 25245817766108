import { collection, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';

const updateCustomization = async (payload, shop, firebase) => {
  payload["updatedAt"] = new Date().toISOString();
  const customizationRef = doc(collection(firebase.firestore, 'shops', shop, 'customizations'), payload.id);

  try {
    const snap = await getDoc(customizationRef);
    const oldCustomization = snap.exists() ? snap.data() : {};
    await setDoc(customizationRef, { ...oldCustomization, ...payload });
    console.log("successfully updated customization");
  } catch (e) {
    console.log("unable to update customization:", e);
  }
};

const deleteCustomization = async (id, shop, firebase) => {
  const customizationRef = doc(collection(firebase.firestore, 'shops', shop, 'customizations'), id);
  await deleteDoc(customizationRef);
};

export { updateCustomization, deleteCustomization };
